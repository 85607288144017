<div class="spacer"></div>

<h1 class="title-text">Информация о пользователях</h1>
<h2>
  Всего пользователей: <b>{{ items?.countTotal ?? 0 }}</b>
</h2>
<h2>
  Активных пользователей: <b>{{ activeUsers }}</b>
</h2>
<h2>
  Заблокированных пользователей: <b>{{ items?.blockedTotal ?? 0 }}</b>
</h2>
<h2>
  PRO пользователей: <b>{{ items?.withProTotal ?? 0 }}</b>
</h2>

<mat-form-field class="example-full-width">
  <mat-label>Найти по логину</mat-label>
  <input matInput placeholder="Логин" [formControl]="strFormControl" />
</mat-form-field>
<table
  mat-table
  *ngIf="items"
  [dataSource]="items.result"
  matSort
  (matSortChange)="handleSortChange($event)"
  class="mat-elevation-z8"
>
  <ng-container matColumnDef="num">
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      sortActionDescription="Sort by name"
    >
      №
    </th>
    <td mat-cell *matCellDef="let element">{{ element.num }}</td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="fullName">
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      sortActionDescription="Sort by name"
    >
      ФИО
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.fullName }}
      <span *ngIf="element.mode === 'pro'">
        <div
          [title]="element.expiredAt | date: 'short'"
          class="label clickable"
          (click)="picker.open()"
        >
          PRO
        </div>
        истекает
        <input
          matInput
          [matDatepicker]="picker"
          [value]="getExpiredAtDatepickerValue(element.expiredAt)"
          (dateChange)="element.updateProAction($event.value)"
        />
        <mat-datepicker #picker></mat-datepicker>
      </span>
      <div *ngIf="element.roleEditor" class="label">ADMIN</div>
    </td>
  </ng-container>

  <!-- Email Column -->
  <ng-container matColumnDef="email">
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      sortActionDescription="Sort by weight"
    >
      E-mail
    </th>
    <td mat-cell *matCellDef="let element">{{ element.email }}</td>
  </ng-container>

  <ng-container matColumnDef="phone">
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      sortActionDescription="Sort by weight"
    >
      Телефон
    </th>
    <td mat-cell *matCellDef="let element">{{ element.phone }}</td>
  </ng-container>

  <ng-container matColumnDef="organizationName">
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      sortActionDescription="Sort by weight"
    >
      Организация
    </th>
    <td mat-cell *matCellDef="let element">{{ element.organizationName }}</td>
  </ng-container>

  <ng-container matColumnDef="createTimestamp">
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      sortActionDescription="Sort by weight"
    >
      Создан
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.createTimestamp | date: 'short' }}
    </td>
  </ng-container>

  <ng-container matColumnDef="createdAtLogin">
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      sortActionDescription="Sort by weight"
    >
      Дата и время последнего входа в систему
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.createdAtLogin | date: 'short' }}
    </td>
  </ng-container>

  <ng-container matColumnDef="isBlocked">
    <th
      mat-header-cell
      *matHeaderCellDef
      sortActionDescription="Sort by weight"
    >
      Заблокирован?
    </th>
    <td mat-cell *matCellDef="let element">
      <mat-checkbox
        class="example-margin"
        [checked]="element.isBlocked"
        disabled="true"
      ></mat-checkbox>
    </td>
  </ng-container>

  <ng-container matColumnDef="action">
    <th
      mat-header-cell
      *matHeaderCellDef
      sortActionDescription="Sort by weight"
    >
      Действие
    </th>
    <td mat-cell *matCellDef="let element">
      <prlw-action-button
        [key]="element._id"
        [label]="userIsPro(element) ? 'Убрать PRO' : 'Выдать PRO'"
        [action]="
          handleUpdateProAction(
            element,
            userIsPro(element) ? null : add30Days()
          )
        "
      />
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>

<div class="spacer"></div>
<button
  *ngFor="let page of pages"
  mat-button
  [class.mdc-button--outlined]="page === items?.page"
  (click)="handlePageClick(page)"
>
  {{ page }}
</button>
