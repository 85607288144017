<div class="container with-logo" prlwResponsive>
  <prlw-title-logo></prlw-title-logo>
  <prlw-title-label
    *ngIf="(xsmall$ | async) === false"
    [title]="title$ | async"
  ></prlw-title-label>
  <div class="market-countdown" prlwResponsive *ngIf="isAuthorized$ | async">
    <prlw-market-time></prlw-market-time>
  </div>
  <prlw-feedback-help-button
    class="grow"
    *ngIf="(isAuthorized$ | async) && (xsmall$ | async) === false"
    (eventShowFeedbackDialog)="showFeedback()"
  ></prlw-feedback-help-button>
  <prlw-title-profile></prlw-title-profile>
</div>
<div class="spacer"></div>
<div class="container" prlwResponsive>
  <prlw-title-label
    *ngIf="(xsmall$ | async) === true"
    [title]="title$ | async"
  ></prlw-title-label>
  <div class="grow"></div>
  <prlw-feedback-help-button
    *ngIf="(isAuthorized$ | async) && (xsmall$ | async) === true"
    (eventShowFeedbackDialog)="showFeedback()"
  ></prlw-feedback-help-button>
  <prlw-mobile-menu-sandwich-button-ui
    *ngIf="(xsmall$ | async) === true"
    (eventClick)="handleMobileMenuSandwichButtonClick()"
  ></prlw-mobile-menu-sandwich-button-ui>
</div>
