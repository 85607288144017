import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { ActionButtonComponent } from './action-button.component';
import { ActionButtonService } from '../../../infrastructure/action-button/action-button.service';
import { of } from 'rxjs';

@Component({
  selector: 'prlw-action-button',
  standalone: true,
  imports: [CommonModule, ActionButtonComponent],
  templateUrl: './action-button.container.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionButtonContainer implements OnInit {
  @Input() key = 'foobar';
  @Input() label = 'foobar';
  @Input() action: () => Promise<unknown> = () => Promise.resolve();

  constructor(private actionButtonService: ActionButtonService) {}

  pending$ = of(false);
  ngOnInit(): void {
    this.pending$ = this.actionButtonService.getPendingStreamByKey(this.key);
  }

  handleClick() {
    this.actionButtonService.makeAction(this.key, this.action);
  }
}
