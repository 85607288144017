import { Injectable, OnDestroy } from '@angular/core';
import { observableWithPending } from '@prlw/infrastructure/observableWithPending-v2';
import {
  BehaviorSubject,
  Subject,
  from,
  map,
  takeUntil,
  withLatestFrom,
} from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ActionButtonService implements OnDestroy {
  destroy$ = new Subject<void>();
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  pending$ = new BehaviorSubject<{ key: string; value: boolean }[]>([]);

  getPendingStreamByKey(key: string) {
    return this.pending$.pipe(
      map(
        (items) => items.filter((item) => item.key === key)[0]?.value ?? null,
      ),
    );
  }

  makeAction(key: string, action: () => Promise<unknown>) {
    observableWithPending(from(action()))
      .pipe(withLatestFrom(this.pending$), takeUntil(this.destroy$))
      .subscribe(([res, pending]) => {
        const nextItems = pending.filter((item) => item.key !== key);
        this.pending$.next([
          ...nextItems,
          {
            key,
            value: res.pending,
          },
        ]);
      });
  }
}
